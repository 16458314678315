import React from 'react'
import { SiteHeader as Component } from '@swp/components'
import items from './ItemSets'

const languages = []

export default function SiteHeader({ set = '', currentLanguage }) {
return (
  <Component className="pos--fix --spacing3 --size-M" type={"SiteHeader"} fixed={true} layout={"3"} transparent={false} langaugeSelector={false} layoutMobile={"1"} social={{"facebook":"profile.php?id=61558975274063","instagram":"studiopetranovakova?igsh=NXJsNDNpMXJkZnlm"}} logo={{"src":"https://cdn.swbpg.com/o/36990/ee61451f553642b0ad8b05c77a60c559.png"}} title={{"color":"rgba(255,255,255,1)","content":"","className":""}} button={{"url":"https://studio-loreal-web.mystoodio.app/cms/reservations/business/1/services","use":"url","color":"rgba(255,255,255,1)","content":"CHCI SE OBJEDNAT","className":"btn-box btn-box--hvr2 btn-box--pbtn4 btn-box--shape5 btn-box--shadow4 fs--16 w--400 swpf--uppercase"}} item={{"color":"rgba(255,255,255,1)","colorHover":"rgba(218,186,111,1)","className":"ff--2 fs--18"}} items={items?.[set]?.items} languages={languages} currentLanguage={currentLanguage} />
)
}